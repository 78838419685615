import axios from 'axios'
import AuthToken from '@/auth/auth-token'
import { paginate } from './paginate'
import qs from 'qs'

class ItemOrder {
  constructor () {
    const base = axios.create({
      baseURL: process.env.VUE_APP_PROMO_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.itemOrder = base
  }

  async getItemOrders (party_id, item_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: '/item_orders',
        method: 'GET',
        params: { party_id, item_id, offset, limit }
      })
    }
    return this.itemOrder({
      url: '/item_orders',
      method: 'GET',
      params: { party_id, item_id, offset, limit }
    })
  }

  async getProcessedItemOrders(party_id, feature_id, item_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/processed_order/${party_id}/${feature_id}`,
        method: 'GET',
        params: { item_id, limit, offset }
      })
    }
    return this.itemOrder({
      url: `/processed_order/${party_id}/${feature_id}`,
      method: 'GET',
      params: { item_id, limit, offset }
    })
  }

  async getAdItemOrders (ad_id, party_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/item_orders/${ad_id}/${party_id}`,
        method: 'GET',
        params: { offset, limit }
      })
    }
    return this.itemOrder({
      url: `/item_orders/${ad_id}/${party_id}`,
      method: 'GET',
      params: { offset, limit }
    })
  }

  async getAdProcessedItemOrders (ad_id, party_id, offset = 0, limit = 50, returnAll = false) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    if (returnAll) {
      return paginate(this.itemOrder, {
        url: `/processed_orders/${ad_id}/${party_id}`,
        method: 'GET',
        params: { offset, limit }
      })
    }
    return this.itemOrder({
      url: `/processed_orders/${ad_id}/${party_id}`,
      method: 'GET',
      params: { offset, limit }
    })
  }

  async resetSuggestedOrder(ad_id, party_id, batch_id, item_id, meat) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/reset_suggested_order_grid/${party_id}/${ad_id}/${batch_id}`,
      method: 'POST',
      params: { item_id, meat }
    })
  }

  async post (orderData, edge_sub) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: '/item_order/',
      method: 'POST',
      data: orderData,
      params: { suggested_grid: edge_sub }
    })
  }

  async put (orderId, orderData, edge_sub) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/item_order/${orderId}`,
      method: 'PUT',
      data: orderData,
      params: { suggested_grid: edge_sub }
    })
  }

  async delete (orderId, edge_sub) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/item_order/${orderId}`,
      method: 'DELETE',
      params: { suggested_grid: edge_sub }
    })
  }

  async suggestedItemOrderHistory(start_dt, end_dt, upcs, store_num, summary) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.itemOrder({
      url: `/suggested_order_item_history`,
      method: 'GET',
      params: {
        start_dt: start_dt,
        end_dt: end_dt,
        upcs: upcs,
        store_num: store_num,
        summary: summary
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false, encode: false });
      }
    })
  }

  async itemOrderHistory (party_id, ad_id, batch_id, order_qty_request) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.itemOrder({
      url: `/generate_order_qty/${party_id}/${ad_id}/${batch_id}`,
      method: 'POST',
      data: order_qty_request
    })
  }

  async getUserExports(limit = 25, offset = 0) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/suggested_order_exports/`,
      method: 'GET',
      params: { offset: offset, limit: limit }
    })
  }

  async getPresignedUrl(export_id) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })
    return this.itemOrder({
      url: `/order_export_url_by_export_id/${export_id}`,
      method: 'GET',
    })
  }

  async createSuggestedOrderGrid(ad_id, ad_name, related_parties, store_numbers, delivery_days, meat_grid) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.itemOrder({
      url: `/export_suggested_order_grid_to_csv/${ad_id}`,
      method: 'GET',
      params: { related_parties: related_parties, store_numbers: store_numbers, delivery_days: delivery_days, ad_name: ad_name, meat_grid: meat_grid },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false, encode: false })
      }
    })
  }

  async addUserExport(body) {
    await AuthToken.setAuth(this.itemOrder, {
      audience: process.env.VUE_APP_PROMO_AUDIENCE
    })

    return this.itemOrder({
      url: `/suggested_order_exports/`,
      method: 'POST',
      data: body,
    })
  }
}

export default new ItemOrder()
