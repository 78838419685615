var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { flat: "", color: "toolbar" } },
        [
          _c("v-breadcrumbs", {
            attrs: { large: "", items: _vm.breadcrumbs },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-breadcrumbs-item",
                      { attrs: { href: item.href, disabled: item.disabled } },
                      [
                        _c(
                          "span",
                          { class: !item.disabled ? "primary--text" : "" },
                          [_vm._v(" " + _vm._s(item.text) + " ")]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("v-divider", {
            staticClass: "mx-4",
            attrs: { vertical: "", inset: "" },
          }),
          _c(
            "v-row",
            {
              staticClass: "flex-nowrap",
              attrs: { align: "center", dense: "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "flex-nowrap flex-shrink-1",
                  attrs: { align: "start" },
                },
                [
                  _c("span", { staticClass: "text-subtitle-1" }, [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(" Ad Dates: "),
                    ]),
                    _c("span", { staticStyle: { "margin-left": "2px" } }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatDateDisplay(_vm.adPlanner.ad_start_date)
                          ) +
                          " - " +
                          _vm._s(
                            _vm.formatDateDisplay(_vm.adPlanner.ad_end_date)
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm.getsSuggestedOrders
                ? _c(
                    "v-col",
                    { staticClass: "d-flex shrink" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "amber darken-3 white--text text-capitalize mx-1 flex-nowrap",
                          attrs: {
                            height: "38",
                            width: "20",
                            disabled: _vm.checkedItems.length < 1,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.barChart = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-chart-timeline-variant")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.getsSuggestedOrders
                ? _c(
                    "v-col",
                    { attrs: { cols: "2" } },
                    [
                      _c("SuggestedOrderStatus", {
                        attrs: {
                          adPlanner: _vm.adPlanner,
                          store_id: _vm.store_id,
                          orderInfo: _vm.orderingInfo,
                          isManager: _vm.isEdgeManager,
                        },
                        on: { updated: _vm.updateStatus },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "mx-1 d-flex ml-auto", attrs: { cols: "4" } },
                [
                  _c("SearchFilters", {
                    attrs: { departments: _vm.departments, pages: _vm.pages },
                    model: {
                      value: _vm.searchParams,
                      callback: function ($$v) {
                        _vm.searchParams = $$v
                      },
                      expression: "searchParams",
                    },
                  }),
                ],
                1
              ),
              _vm.getsSuggestedOrders
                ? _c(
                    "v-col",
                    { staticClass: "d-flex shrink" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            left: "",
                            "offset-y": "",
                            "nudge-bottom": "3",
                            "close-on-content-click": "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on: menu, attrs }) {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on: tooltip }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {},
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      { ...tooltip, ...menu }
                                                    ),
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Actions")])]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2384505429
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.showExportModal = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { color: "blue" },
                                    },
                                    [_vm._v("mdi-file-excel-outline")]
                                  ),
                                  _vm._v(" Exports "),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    disabled:
                                      !_vm.resetToOriginalSelectionActive,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showResetSuggestedOrderModal = true
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        disabled:
                                          !_vm.resetToOriginalSelectionActive,
                                        color: "red",
                                      },
                                    },
                                    [_vm._v("mdi-arrow-u-left-top")]
                                  ),
                                  _vm._v(" Reset Suggested Grid "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        ref: "my-table",
        attrs: {
          headers: _vm.headers,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.groupedItems,
          "item-class": _vm.rowHighlight,
          loading: _vm.loading,
          "group-by": _vm.getsSuggestedOrders ? "comboKey" : null,
          "sort-by": "['page_name', 'position_name']",
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "loading-text": "Loading... Please Wait",
          "no-data-text": "No Retail Ad Items to Display",
          "no-results-text": "No Matches Found",
          "item-key": "ad_plan_id",
          expanded: _vm.expanded,
          "show-select": _vm.getsSuggestedOrders,
        },
        on: {
          "update:items": function ($event) {
            _vm.groupedItems = $event
          },
          "update:expanded": function ($event) {
            _vm.expanded = $event
          },
          "click:row": function ($event) {
            _vm.highlighted = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.components`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                item.is_shipper
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: { icon: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showComponentsModal(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("mdi-view-list")])],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("Components")])]
                  ),
                ]
              },
            },
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    {
                      staticClass: "expandedRow",
                      class:
                        item.item_id === _vm.highlighted.item_id
                          ? "primary white--text"
                          : "",
                      staticStyle: { cursor: "pointer" },
                      attrs: { colspan: headers.length },
                      on: {
                        click: function ($event) {
                          _vm.highlighted = item
                        },
                      },
                    },
                    [
                      _vm.items.length && _vm.showDeliveryDates
                        ? _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _vm._l(item.item_orders, function (order) {
                                return _c(
                                  "v-col",
                                  {
                                    key: order.item_id,
                                    staticClass: "pt-1 mb-3",
                                    staticStyle: { height: "65px" },
                                    attrs: { cols: "1" },
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mt-2",
                                      attrs: {
                                        disabled: item.is_dsd,
                                        readonly:
                                          order.processed ||
                                          _vm.orderingClosed(item, order),
                                        outlined: "",
                                        solo: "",
                                        "background-color":
                                          order.processed ||
                                          _vm.orderingClosed(item, order)
                                            ? "grey lighten-3"
                                            : "white",
                                        placeholder: _vm.orderingClosed(
                                          item,
                                          order
                                        )
                                          ? "Closed"
                                          : "Quantity",
                                        hint: _vm.getHint(order, item),
                                        "persistent-hint": "",
                                        dense: "",
                                        type: "number",
                                        color:
                                          order.processed ||
                                          _vm.orderingClosed(item, order)
                                            ? "grey darken-1"
                                            : "primary",
                                        label: _vm
                                          .moment(order.delivery_date)
                                          .format("ddd_MM/DD"),
                                        value:
                                          order.quantity > 0
                                            ? order.quantity
                                            : null,
                                        min: "1",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          _vm.activeInput = order
                                        },
                                        change: function ($event) {
                                          return _vm.setOrder(
                                            item,
                                            order,
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend-inner",
                                            fn: function () {
                                              return [
                                                !_vm.disableSuggestedTip(
                                                  order,
                                                  item
                                                )
                                                  ? _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          icon: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.setOrder(
                                                                                item,
                                                                                order,
                                                                                order.suggested_qty
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "green",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-plus-circle-outline"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Apply Suggested Order"
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        order.processed
                                                                          ? "green"
                                                                          : _vm.orderingClosed(
                                                                              item,
                                                                              order
                                                                            )
                                                                          ? "red"
                                                                          : "",
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        order.processed
                                                                          ? "mdi-checkbox-marked-circle-outline"
                                                                          : ""
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          order.processed
                                                            ? "Order Processed"
                                                            : _vm.orderingClosed(
                                                                item,
                                                                order
                                                              )
                                                            ? "Ordering Closed"
                                                            : ""
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "ml-4 mt-5 d-flex justify-center",
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      staticClass: "font-weight-medium",
                                      attrs: {
                                        color: "green",
                                        value: item.total_ordered >= 1,
                                        content: item.total_ordered,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color:
                                              item.item_id ===
                                              _vm.highlighted.item_id
                                                ? "white"
                                                : "",
                                          },
                                        },
                                        [_vm._v("mdi-cart-outline")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.getsSuggestedOrders
                                    ? [
                                        !_vm.orderingClosed(item)
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-4",
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    item.total_ordered <
                                                                    1,
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.clearItemOrders(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "red",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-trash-can"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Clear Cart"),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.orderingClosed(item)
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                class: {
                                                                  "grey--text":
                                                                    !_vm.getOrderChanged(
                                                                      item
                                                                    ),
                                                                  "icon-circle":
                                                                    _vm.getOrderChanged(
                                                                      item
                                                                    ),
                                                                  "icon-circle-active":
                                                                    _vm.getOrderChanged(
                                                                      item
                                                                    ) &&
                                                                    item.item_id ===
                                                                      _vm
                                                                        .highlighted
                                                                        .item_id,
                                                                },
                                                                attrs: {
                                                                  icon: "",
                                                                  disabled:
                                                                    !_vm.getOrderChanged(
                                                                      item
                                                                    ),
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.resetSuggestedOrder(
                                                                        item.item_id
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    size: "19",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-reload"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "Reset To Originally Suggested Orders"
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "v-col",
                                { staticClass: "ma-2", attrs: { cols: "2" } },
                                [
                                  _c("span", [
                                    _c("b", { staticClass: "pr-2" }, [
                                      _vm._v("AMAP Dates:"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.amap_start_date &&
                                            item.amap_end_date
                                            ? `${_vm.formatDateDisplay(
                                                item.amap_start_date
                                              )} - ${_vm.formatDateDisplay(
                                                item.amap_end_date
                                              )}`
                                            : "---"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("br"),
                                  _c("span", [
                                    _c("b", { staticClass: "pr-2" }, [
                                      _vm._v("TPR Dates:"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.tpr_start_date &&
                                            item.tpr_end_date
                                            ? `${_vm.formatDateDisplay(
                                                item.tpr_start_date
                                              )} - ${_vm.formatDateDisplay(
                                                item.tpr_end_date
                                              )}`
                                            : "---"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", align: "center" } },
                                [
                                  item.is_shipper
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize",
                                          staticStyle: { "margin-top": "10px" },
                                          attrs: {
                                            height: "38",
                                            text: "",
                                            color:
                                              item.item_id ===
                                              _vm.highlighted.item_id
                                                ? "white"
                                                : "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.showComponentsModal(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-magnify")]
                                          ),
                                          _vm._v(" Components "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.last_90_days_shipped_qty`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      _vm._s(
                        item.last_90_days_shipped_qty
                          ? item.last_90_days_shipped_qty
                          : "---"
                      )
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_dates`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.tpr_start_date && item.tpr_end_date
                            ? `${_vm.formatDateDisplay(
                                item.tpr_start_date
                              )} - ${_vm.formatDateDisplay(item.tpr_end_date)}`
                            : "---"
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.department`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.department)),
                  ]),
                ]
              },
            },
            {
              key: `item.description`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.description)),
                  ]),
                ]
              },
            },
            {
              key: `item.upc`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.upc ? item.upc : "---")),
                  ]),
                ]
              },
            },
            {
              key: `item.case_cost`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.case_cost)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.amap`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(" $" + _vm._s(_vm._f("format")(item.amap)) + " "),
                  ]),
                ]
              },
            },
            {
              key: `item.early_buy_billback`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(_vm._f("format")(item.early_buy_billback)) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.ad_billback`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.ad_billback)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_billback`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.tpr_billback)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_billback`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.edlc_billback)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.ad_scan`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(" $" + _vm._s(_vm._f("format")(item.ad_scan)) + " "),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_scan`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.tpr_scan)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_scan`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.edlc_scan)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.net_unit_cost`,
              fn: function ({ item }) {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "$" + _vm._s(_vm._f("format")(item.net_unit_cost))
                        ),
                      ]),
                      _vm.tenant === "alliance-retail-group" &&
                      item.deal_cost_dollar_savings != null &&
                      item.deal_cost_percent_savings != null
                        ? _c(
                            "v-menu",
                            {
                              attrs: {
                                "open-on-hover": "",
                                "offset-y": "",
                                top: "",
                                "close-on-content-click": false,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "pa-0 ma-0",
                                              attrs: { small: "", icon: "" },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "ma-0 pa-0" },
                                              [
                                                _vm._v(
                                                  "mdi-information-outline"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-text", [
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Dollar Savings:")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            color: "#007bff",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " $" +
                                              _vm._s(
                                                item.deal_cost_dollar_savings
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                          },
                                        },
                                        [_vm._v("Percent Savings:")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "bold",
                                            color: "#007bff",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.deal_cost_percent_savings
                                              ) +
                                              "%"
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.unit_cost`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" + _vm._s(_vm._f("format")(item.unit_cost)) + " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.ad_retail`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(item.ad_retail_count) +
                        "/$" +
                        _vm._s(_vm._f("format")(item.ad_retail)) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.ad_gross_profit_percent`,
              fn: function ({ item }) {
                return [
                  _c("span", { staticClass: "noWrap" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("format")(item.ad_gross_profit_percent)) +
                        "% "
                    ),
                  ]),
                ]
              },
            },
            _vm.getsSuggestedOrders
              ? {
                  key: `group.header`,
                  fn: function ({ group, headers, items, isOpen, toggle }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "blue-grey lighten-2 white--text",
                          staticStyle: {
                            height: "40px",
                            "border-top": "1px solid grey !important",
                          },
                          attrs: { colspan: headers.length },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "", align: "center" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: group,
                                  attrs: {
                                    dark: "",
                                    small: "",
                                    icon: "",
                                    "data-open": isOpen,
                                  },
                                  on: { click: toggle },
                                },
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          isOpen
                                            ? "mdi-chevron-up"
                                            : "mdi-chevron-down"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "ml-1 font-weight-medium" },
                                [_vm._v(" " + _vm._s(group) + " ")]
                              ),
                              _c("v-divider", {
                                staticClass: "mx-4 mb-1 white",
                                attrs: {
                                  inset: "",
                                  vertical: "",
                                  length: "10",
                                },
                              }),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { color: "main", top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-badge",
                                              {
                                                attrs: {
                                                  value: items.some(
                                                    ({ feature_note }) =>
                                                      feature_note
                                                  ),
                                                  right: "",
                                                  color: "amber darken-2",
                                                  overlap: "",
                                                  dot: "",
                                                  "offset-x": "15",
                                                  "offset-y": "15",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openNote(
                                                              items,
                                                              group
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "mdi-note-text-outline"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(" Notes ")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          staticStyle: { "max-width": "150px" },
                          attrs: { cols: "2" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "mt-8",
                            attrs: {
                              dense: "",
                              items: _vm.pageSizes,
                              label: "Items Per Page",
                              "menu-props": {
                                top: true,
                                offsetY: true,
                                maxHeight: 500,
                              },
                            },
                            model: {
                              value: _vm.pageSize,
                              callback: function ($$v) {
                                _vm.pageSize = $$v
                              },
                              expression: "pageSize",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disablePrevious,
                          },
                          on: { click: _vm.previous },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                        1
                      ),
                      _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            icon: "",
                            large: "",
                            disabled: _vm.disableNext,
                          },
                          on: { click: _vm.next },
                        },
                        [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.checkedItems,
          callback: function ($$v) {
            _vm.checkedItems = $$v
          },
          expression: "checkedItems",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.dialog },
          on: {
            "update:value": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    { staticClass: "red--text mr-3", attrs: { size: "35" } },
                    [_vm._v("mdi-alert-octagon")]
                  ),
                  _c("span", { staticClass: "font-weight-light" }, [
                    _vm._v("Unable to Proceed"),
                  ]),
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(" No order delivery days are assigned to store. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-capitalize font-weight-medium",
                      attrs: { text: "", to: { path: "/retail-manager" } },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.adPlanner && _vm.showComponents
        ? _c("ComponentItems", {
            attrs: {
              ad_id: _vm.adPlanner.ad_id,
              selectedItem: _vm.selectedItem,
            },
            on: {
              close: function ($event) {
                _vm.showComponents = false
              },
            },
            model: {
              value: _vm.showComponents,
              callback: function ($$v) {
                _vm.showComponents = $$v
              },
              expression: "showComponents",
            },
          })
        : _vm._e(),
      _c("ChartModal", {
        attrs: {
          value: _vm.barChart,
          selected: _vm.checkedItems,
          adPlanner: _vm.adPlanner,
          meat_grid: _vm.adPlanner.meat_grid,
          store_id: _vm.store_id,
          storeParties: _vm.storeParties,
          storeDeliveryDays: _vm.storeDeliveryDays,
          party_id: _vm.store_id,
        },
        on: {
          "update:value": function ($event) {
            _vm.barChart = $event
          },
          confirmUpdate: _vm.confirmUpdate,
        },
      }),
      _vm.showExportModal
        ? _c("ExportModal", {
            attrs: {
              value: _vm.showExportModal,
              ad_id: _vm.adPlanner.ad_id,
              ad_name: _vm.adPlanner.ad_name,
              meat_grid: _vm.adPlanner.meat_grid,
              storeDeliveryDays: _vm.storeDeliveryDays,
              store_id: _vm.store_id,
              storeParties: _vm.storeParties,
            },
            on: {
              "update:value": function ($event) {
                _vm.showExportModal = $event
              },
              close: _vm.closeModal,
            },
          })
        : _vm._e(),
      _vm.showResetSuggestedOrderModal
        ? _c("ResetSuggestedOrdersModal", {
            attrs: { value: _vm.showResetSuggestedOrderModal },
            on: {
              "update:value": function ($event) {
                _vm.showResetSuggestedOrderModal = $event
              },
              reset: _vm.resetSuggestedOrder,
            },
          })
        : _vm._e(),
      _vm.showConfirm
        ? _c("ConfirmDialog", {
            attrs: { value: _vm.showConfirm, options: _vm.options },
            on: {
              "update:value": function ($event) {
                _vm.showConfirm = $event
              },
              confirm: _vm.applySelectedHistoricalSuggestedOrders,
            },
          })
        : _vm._e(),
      _c("FeatureNote", {
        attrs: { value: _vm.showNote, item: _vm.selectedItem },
        on: {
          "update:value": function ($event) {
            _vm.showNote = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }